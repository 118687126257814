import React from "react"
import Layout from "../../components/layout"
import { Helmet } from "react-helmet"
import {
  Section,
  SectionHeading,
} from "../../styledComponents/section"

const Heading = ({ level = 1, location, children, align }) => (
  <SectionHeading as={`h${level}`} level={level} location={location} align={align}>
    {children}
  </SectionHeading>
)

const PermissioningRelax = ({ data, location }) => {
  
    return (
      <>
      <Helmet>
        <meta name={`robots`} content={`noindex, nofollow`} />x
      </Helmet>
      <Layout location={location} isHomePage>
        <Section>
          <Heading level={1} align="center">
            Relax
          </Heading>
        </Section>
      </Layout>
      </>
    )
}

export default PermissioningRelax
